<!--
 * @Date: 2021-01-12 13:36:19
 * @LastEditTime: 2022-03-22 13:53:35
 * @Author: zhangyang
 * @LastEditors: ZhangYang
 * @Description: 移动端 关于我们
 * @FilePath: \portal\src\views\mobile_my\index.vue
-->
<template>
  <div class="mobileMy">
    <comp-mobile-head :navIndex="2" :logoColor="scrollTop > 0 ? true : false" :bgColor="scrollTop > 0 ? true : false" />
    <section class="content">
      <div class="company_explain">
        <div class="box">
          <h2 class="font-style text-48">关于我们</h2>
          <h3 class="not-width text-28">About Us</h3>
          <div class="text-28" style="margin: 0.48rem 0 0.5rem 0;">
            享库存是优秀的的本地生活全域营销平台，成立于2019年，专注为本地生活商家提供全域营销服务。通过抖音、微信、携程、自媒体多种渠道，帮助本地商家完成新用户触达、再营销和忠诚度提升。公司是抖音、小红书、携程等平台战略合作伙伴，累计服务超过10000个餐饮品牌。截至目前，享库已在上海（总部）、北京、苏州、杭州、南京、武汉、深圳、西安、重庆、无锡等近30个城市开设分站。
          </div>
          <div>
            <img src="https://cdn2.xxkucun.com/xxkucun_web/pc_photo_family.png" alt="" style="width:100%;" ondragstart="return false;" oncontextmenu="return false;">
          </div>
        </div>
      </div>
      <div class="troika">
        <div class="box">
          <div class="i-box">
            <div class="item flex align justify" v-for="(e,i) in mottoList" :key='i'>
              <div class="right">
                <div>
                  <img :src="e.icon" :alt="e.name">
                </div>
                <div class="title text-32">{{e.name}}</div>
                <div class="text-24">{{e.info}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company-environment">
        <div class="box">
          <h2 class="font-style text-48">知识产权</h2>
          <h3 class="not-width text-28">Intellectual Property</h3>
          <div class="certification_card_box flex justify align wrap">
            <div class="card text-14" v-for="(ev, index) in certification_list" :key="index">
              <div class="card_img">
                <img :src="ev.url" alt="" ondragstart="return false;" oncontextmenu="return false;">
              </div>
              <div class="text-32">{{ev.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="my-address-box">
        <h2 class="font-style text-48">公司地址</h2>
        <h3 class="not-width text-28">Company Address</h3>
        <!-- <div class="box flex between align">
          <div class="left">
            <div class="address-list-item" v-for="(e,i) in addressList" :key='i' @mouseover="addressIndex = i">
              <h4 :class="addressIndex==i?'text-24':'text-18'">{{e.name}}</h4>
              <div class="desc text-14">{{e.address}}</div>
              <div class="desc text-14 flex align phone_text">
                <img src="../../assets/img/common/phone.png" alt="" style="width:16px;height:16px;margin-right:5px;">
                <span>{{e.phone}}</span>
              </div>
            </div>
          </div>
          <div class="right">
            <baidu-map class="map" :center="{lng: addressList[addressIndex].lng, lat: addressList[addressIndex].lat}" :zoom="20" :scroll-wheel-zoom="true">
              <bm-marker :position="{lng: addressList[addressIndex].lng, lat: addressList[addressIndex].lat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                <bm-label :content="`享库存${addressList[addressIndex].name}`" :labelStyle="{color: '#FF163A', fontSize : '12px', border: 'none', background: 'none'}" :offset="{width: -25, height: 20}"/>
              </bm-marker>
            </baidu-map>
          </div>
        </div> -->
        <div class="box_2_0 flex justify align wrap">
          <div class="card" v-for="(e,i) in addressList" :key='i'>
              <div class="map_box">
                <baidu-map class="map" :center="{lng: e.lngAadLat[0], lat: e.lngAadLat[1]}" :zoom="20" :scroll-wheel-zoom="true">
                  <bm-marker :position="{lng:e.lngAadLat[0], lat: e.lngAadLat[1]}" :dragging="true" >
                    <!-- <bm-label :content="`享库存${e.name}`" :labelStyle="{color: '#FF163A', fontSize : '12px', border: 'none', background: 'none'}" :offset="{width: -25, height: 20}"/> -->
                  </bm-marker>
                </baidu-map>
              </div>
              <div class="card_text_box">
                <h4 class="text-48 mar_t_12 pad_l_10">{{e.name}}</h4>
                <div class="desc text-28 mar_t_12 pad_l_10">{{e.address}}</div>
                <!-- <div class="desc text-28 flex align phone_text mar_t_12 pad_l_10">
                  <img src="../../assets/img/common/phone.png" alt="" style="width:18px;height:18px;margin-right:5px;">
                  <span><a :href="`tel:${e.phone}`">{{e.phone}}</a></span>
                </div> -->
              </div>
          </div>
        </div>
      </div>
      <div class="join_my_box">
        <div class="title">
          <h2 class="font-style text-40">欢迎加入我们</h2>
          <h3 class="not-width text-24 desc">邮箱：hr@xkcun.com</h3>
        </div>
        <div class="code">
          <div class="img">
            <img src="../../assets/img/common/xkc_boss.jpg" alt="Boss直聘">
          </div>
          <div class="text-24 desc">扫码查看岗位/投递简历</div>
        </div>
      </div>
    </section>
    <comp-mobile-footer :scrollTop="scrollTop" :maxScrollTop='18000' />
  </div>
</template>

<script>
export default {
  name: 'MobileMy',
  data() {
    return {
      scrollTop: 0,
      aboutCompany:'享库存由上海享评信息科技有限公司于2019年创办，目前公司总部在上海，通过创新的S2b2C模式，上游打通本地商户的库存API，为本地商户优化库存管理，下游服务分销商、兼职（白领/宝妈/学生/上班族等），为他们提供爆款低价货源，借助社交电商的爆发力，降低商品零售交易成本，让消费者以更便捷的方式和更优惠的价格享受到优质服务。目前平台已吸引超上百家分销商、几十万分销达人、上千家本地知名品牌入驻，热销商品几万件，触达上千万本地消费者。',
      mottoList:[
        {icon:'https://cdn2.xxkucun.com/portal/community/content_icon_2.png',name:'愿景',info:'成为优秀的本地生活开放平台'},
        {icon:'https://cdn2.xxkucun.com/portal/community/content_icon_1.png',name:'使命',info:'让分享变得更有价值'},
        {icon:'https://cdn2.xxkucun.com/portal/community/content_icon_3.png',name:'价值观',info:'正直诚信 开放共赢 追求极致 永不放弃'},
      ],
      certification_list:[
        // {title:'合同信用等级认定证书',url:require('../../assets/img/common/certificate.jpg')},
        // {title:'上海市守合同重信用企业',url:require('../../assets/img/common/certificate.jpg')},
        {title:'享库-高新技术企业证书',url:'https://cdn2.xxkucun.com/portal/common/certificate.jpg'},
        // {title:'软件企业证书2021-2022',url:require('../../assets/img/common/certificate.jpg')},
        // {title:'软件产品证书--再惠商家管理',url:require('../../assets/img/common/certificate.jpg')},
        // {title:'软件产品证书--再惠外卖代运营管理软件',url:require('../../assets/img/common/certificate.jpg')},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/1.jpg'},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/2.jpg'},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/3.jpg'},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/4.jpg'},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/5.jpg'},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/6.jpg'},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/7.jpg'},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/8.jpg'},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/9.jpg'},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/10.jpg'},
        {title:'计算机软件著作权登记证书',url:'https://cdn2.xxkucun.com/portal/certificate/11.jpg'},
      ],
      addressList: [
        {name: '上海总部', address: '上海市徐汇区虹漕路68号锦和中心11楼11C/11E', phone: '17816181904',lngAadLat:[121.417072,31.176505]},
        // {name: '上海', address: '上海市徐汇区虹漕路68号锦和中心5楼5D', phone: '17717640257',lngAadLat:[121.417072,31.176505]},
        {name: '杭州站', address: '浙江省杭州市西湖区文二路328号华星发展大厦A座537~538室', phone: '17051036040',lngAadLat:[120.131052,30.289092]},
        {name: '苏州站', address: '江苏省苏州市姑苏区广济南路258号百脑汇科技中心1617-1619', phone: '19533771892',lngAadLat:[120.607115,31.317019]},
        {name: '南京站', address: '江苏省南京市秦淮区洪武路198号城开大厦1802室', phone: '13122317338',lngAadLat:[118.792832,32.04035]},
        {name: '武汉站', address: '湖北省武汉市武昌区中北路265号世纪大厦1903室', phone: '18818276613',lngAadLat:[114.361941,30.577379]},
        {name: '北京站', address: '北京市朝阳区东三环南路甲52号楼顺迈金钻3C室', phone: '19821185288',lngAadLat:[116.468929,39.892867]},
        {name: '西安站', address: '西安市环城南路48号城市郦景301室', phone: '13761110735',lngAadLat:[108.947752,34.256696]},
        {name: '无锡站', address: '无锡市梁溪区中山路288号云蝠大厦28楼享库存2811室', phone: '13771099744',lngAadLat:[120.306613,31.583494]},
        {name: '重庆站', address: '重庆渝北嘉州协信B栋18-13', phone: '13356731914',lngAadLat:[106.530427,29.595155]},
        {name: '深圳站', address: '深圳福田区泰然六路苍松大厦北座3A08室', phone: '13564814906',lngAadLat:[114.033144,22.538618]},
        {name: '成都站', address: '四川省成都市锦江区梨花街50号电力大楼15层1-1501室', phone: '17826116879',lngAadLat:[104.072816,30.661234]},
        {name: '长沙站', address: '湖南省长沙市开福区五一大道868号新世界大厦1215室', phone: '15629634520',lngAadLat:[112.982559,28.201538]},
        {name: '合肥站', address: '安徽省合肥市蜀山区蔚蓝商务港F座806室', phone: '13918132913',lngAadLat:[117.239347,31.81996]},
        {name: '济南站', address: '济南市历城区二环东路3966号东环国际广场A座11楼C01', phone: '13176699288',lngAadLat:[117.08083,36.678869]},
        {name: '郑州站', address: '郑州市金水区紫荆山路1号紫荆山百货5楼UFO.WORK', phone: '13676983998',lngAadLat:[113.68795,34.76802]},
        {name: '太原站', address: '山西省太原市万柏林区迎泽西大街120号1-2号写字楼B座13楼', phone: '15234041279',lngAadLat:[112.516003,37.86253]},
        {name: '宁波站', address: '浙江省宁波市海曙区车轿街69号恒泰大厦2楼优客工场AR26室', phone: '15023303578',lngAadLat:[121.563889,29.875882]},
        {name: '广州站', address: '广州市天河区天河路518号8楼创富港C11室', phone: '18318882798',lngAadLat:[113.335556,23.144761]},
        {name: '南昌站', address: '江西省南昌市红谷滩新区红谷中大道1368号科研测试基地C栋（鼎峰中央）写字楼C单元2307号房', phone: '17610210310',lngAadLat:[115.864383,28.695595]},
        {name: '常州站', address: '江苏省常州市钟楼区大庙弄2号新闻大厦6楼609室', phone: '13325583608',lngAadLat:[119.961136,31.786119]},
        {name: '佛山站', address: '佛山市禅城区祖庙路29号岭南天地商业大厦8楼809室', phone: '18806650050',lngAadLat:[113.118618,23.033286]},
        {name: '南通站', address: '南通市崇川区虹桥路1号文峰城市广场5号楼19层A区办公室01-02室', phone: '18013190618 ',lngAadLat:[120.889943,32.002983]},
        {name: '厦门站', address: '厦门市思明区厦禾路666-668号海翼大厦B座17层优客工场A1707室', phone: '18605140180 ',lngAadLat:[118.101453,24.469326]},
        {name: '福州站', address: '福州市台江区中央第五街2号楼3009-3010室', phone: '15527886828 ',lngAadLat:[119.298843,26.070258]},
        {name: '昆明站', address: '昆明市西山区大悦城购物中心6号楼D区12层A31室', phone: '15720696355 ',lngAadLat:[102.720002,25.028683]},
        {name: '天津站', address: '天津市和平区大沽北路76号新华国金中心25层2527室', phone: '17695588645 ',lngAadLat:[117.214259,39.13168]},
        {name: '青岛站', address: '青岛市崂山区海尔路182号3号楼16层1605C', phone: '17561650185 ',lngAadLat:[120.460533,36.114449]},
        {name: '石家庄站', address: '石家庄市桥西区休门街3号滨江优谷大厦A1号办公楼1319室', phone: '13653115941 ',lngAadLat:[114.513393,38.045818]},
        {name: '贵阳站', address: '贵州省贵阳市云岩区香狮路嘉福大厦1单元28楼2804号', phone: '15928959701 ',lngAadLat:[106.70855,26.583412]},
        {name: '扬州站', address: '江苏省扬州市刊江区江阳西路109号绿地峰创国际A座614室', phone: '155528633344 ',lngAadLat:[119.395065,32.37018]},
      ],
      addressIndex: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.windowScroll)
  },
  methods: {
    windowScroll() {
      this.scrollTop = document.documentElement.scrollTop
    },
  },
  beforeDestroy() {},
  destroyed() {
      // 清除定时器
      if (this.autoplay) {
          clearInterval(this.intervalTime)
      }
  }
}
</script>
<style lang="less" scoped>
.mobileMy {
  width: 100%;
  min-height: 100vh;
  color: #fff;
  .company_explain {
    width: 100%;
    color: #000;
    padding-top: 100px;
    .box {
      width: 6.8rem;
      margin: 0 auto;
    }
  }
  .troika{
    margin-top: 0.7rem;
    width: 100%;
    color: #000;
    .box {
      width: 6.8rem;
      margin: 0 auto;
    }
    .i-box{
      width: 5.4rem;
      margin: 0.5rem auto;
      .item{
        // justify-content: flex-start;
        margin-bottom: 0.4rem;
        .right{
          text-align: center;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 0.32rem;
          .title{
            color: #000;
            font-weight: 600;
            line-height: 0.45rem;
            margin: 10px auto;
          }
          img{
            width: 0.8rem;
            height: 0.8rem;
          }
        }
      }
    }
  }
  .company-environment {
    width: 100%;
    color: #000;
    background: #FCF8F8;
    box-sizing: border-box;
    padding: 0.5rem 0;
    .box {
      max-width: 100%;
      margin: 0 auto;
      .certification_card_box{
        margin: 0.5rem auto;
        .card{
          margin: 10px;
          width: 343px;
          // height: 327px;
          box-sizing: border-box;
          padding: 12px 12px 24px 12px;
          border-radius: 4px;
          background: #FFFFFF;

          font-family: MicrosoftYaHei;
          font-weight: 0;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0em;
          color: rgba(0, 0, 0, 0.85);
          .card_img{
            margin: 12px auto;
            width: 100%;
            // height: 239px;
            > img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .my-address-box {
    box-sizing: border-box;
    padding: 0.8rem 0 0 0;
    color: #000;
    // .box {
    //   width: 10.68rem;
    //   margin: 0.5rem auto;
    //   text-align: left;

    //   font-family: PingFangSC-Regular, PingFang SC;
    //   font-weight: 400;
    //   .left {
    //     margin-right: 0.2rem;
    //     .address-list-item {
    //       margin: 0.1rem 0;
    //     }
    //     .desc {
    //       color: rgba(0, 0, 0, 0.65);
    //     }
    //     .phone_text{
    //       font-family: MicrosoftYaHei;
    //       font-weight: 0;
    //       line-height: 22px;
    //       letter-spacing: 0em;
    //       color: #FF163A;
    //     }
    //     .mar-top {
    //       margin-top: 0.32rem;
    //     }
    //     .mar-bt {
    //       margin-bottom: 0.24rem;
    //     }
    //   }
    //   .right {
    //     width: 7.3rem;
    //     height: 5.4rem;
    //     background: #ddd;
    //     overflow: hidden;
    //     .map{
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    // }
    .box_2_0{
      max-width: 100%;
      margin: 0 auto;
      margin-top: 0.5rem;
      .card{
        margin: 0 10px;
        width: 343px;
        height: 332px;
        padding: 0px 0px 24px 0px;
        border-radius: 4px;
        overflow: hidden;
        background: #FFFFFF;
        .map_box{
          width: 343px;
          height: 186px;
          .map{
            width: 100%;
            height: 100%;
          }
        }
        .card_text_box{
          text-align: left;
          .desc {
            color: rgba(0, 0, 0, 0.65);
          }
          .phone_text{
            font-family: MicrosoftYaHei;
            font-weight: 0;
            line-height: 22px;
            letter-spacing: 0em;
            color: #FF163A;
            >span >a{
              font-family: MicrosoftYaHei;
              font-weight: 0;
              line-height: 22px;
              letter-spacing: 0em;
              color: #FF163A;
            }
          }
        }
      }
    }
  }
  .join_my_box{
    margin: 0 auto;
    margin-bottom: 40px;
    width: 100%;
    color: #000;
    .title{
      margin-bottom: 0.2rem;
      .desc{}
    }
    .code{
      width: 4rem;
      margin: 0 auto;
      .img{
        width: 2.6rem;
        // height: 2.4rem;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 0.2rem;
        >img{
          width: 100%;
          height: 100%;
        }
      }
      .desc{
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
  .font-style{
    letter-spacing: 0.05rem;
    font-family: PingFangSC-Semibold, PingFang SC;
  }
  .not-width{
    font-weight: 400;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: rgba(0, 0, 0, 0.65);
  }
}
</style>
