<!--
 * @Date: 2021-01-29 17:59:53
 * @LastEditTime: 2022-03-22 12:11:32
 * @Author: zhangyang
 * @LastEditors: ZhangYang
 * @Description: 合作商户
 * @FilePath: \portal\src\views\mobile_cooperation\index.vue
-->
<template>
    <div class="cooperation">
        <comp-mobile-head :navIndex="3" :logoColor="scrollTop > 120 ? true : false" :bgColor="scrollTop > 120 ? true : false" />
        <section class="content">
            <!-- <div class="header">
                <h1 class="font-style text-56">合作伙伴</h1>
            </div> -->
            <div class="cooperation_box">
                <div class="box">
                    <h2 class="font-style text-48">部分合作伙伴</h2>
                    <h3 class="not-width text-28">Partners</h3>
                    <div class="list flex align">
                        <img class="item" v-for="i in 52" :key='i' :src="`https://cdn2.xxkucun.com/portal/icon/${i}.png`" alt="合作伙伴" draggable="false">
                    </div>
                </div>
            </div>
        </section>
        <comp-mobile-footer :scrollTop="scrollTop" :maxScrollTop='480' />
    </div>
</template>
<script>
export default {
    name:'Cooperation',
    data() {
        return {
            scrollTop:0
        }
    },
    mounted() {
        window.addEventListener('scroll', this.windowScroll)
    },
    methods:{
        windowScroll() {
            this.scrollTop = document.documentElement.scrollTop
        }
    }
}
</script>
<style lang="less" scoped>
    .cooperation{
        width: 100%;
        min-height: 100vh;
        .cooperation_box{
            margin: 0.6rem 0;
            margin-top:100px;
            width: 100%;
            color: #000;
            .box{
                width: 6.8rem;
                margin: 0 auto;
            }
            .list{
                width: 100%;
                flex-wrap: wrap;
                margin-top: 0.2rem;
                box-sizing: border-box;
                // padding: 0.2rem;
                .item{
                    width: 2rem;
                    margin: 0.12rem;
                }
            }
        }
        .font-style{
            letter-spacing: 0.05rem;
            font-family: PingFangSC-Semibold, PingFang SC;
        }
        .not-width{
            font-weight: 400;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: rgba(0, 0, 0, 0.65);
        }
    }
</style>
