<!--
 * @Date: 2021-01-12 13:36:09
 * @LastEditTime: 2022-03-18 12:46:23
 * @Author: zhangyang
 * @LastEditors: ZhangYang
 * @Description: 移动端首页
 * @FilePath: \portal\src\views\mobile_home\index.vue
-->
<template>
  <div class="home">
      <comp-mobile-head :navIndex="0" :logoColor="scrollTop > 0 ? true : false" :bgColor="scrollTop > 0 ? true : false"  />
      <div class="home_box">
        <section class="content">
          <div class="banner_box">
            <div class="box flex between align wrap">
              <div class="banner_left_box">
                <div class="banner_title">
                  <div class="text-56 font-bold">本地生活数字化</div>
                  <div class="text-56 font-bold">营销服务平台</div>
                  <div class="text-32 font-bold" style="margin:12px 0;">"交易型Saas+服务"让商家开店更简单</div>
                  <div class="text-32" >高效的帮助商家提升曝光纳客，</div>
                  <div class="text-32" >助力餐饮企业实现数字化经营</div>
                </div>
                <!-- <div class="banner_btn">注册体验</div> -->
                <div class="banner_btn" @click="toPage">了解更多</div>
              </div>
              <img src="https://cdn2.xxkucun.com/portal/home/banner_right.png" alt="" ondragstart="return false;">
            </div>
          </div>
          <div class="content_img_box">
            <div class="img_box" v-for="(e,i) in 7" :key="i">
              <img :src="`https://cdn2.xxkucun.com/portal/mobile_home/mobile_home_content_${e}.png`" alt="" ondragstart="return false;" oncontextmenu="return false;">
            </div>
          </div>
      </section>
      </div>
      <comp-mobile-footer :scrollTop="scrollTop" :maxScrollTop='6500' />
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      scrollTop: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.windowScroll)
  },
  methods: {
    windowScroll() {
      this.scrollTop = document.documentElement.scrollTop
    },
    toPage(){
      this.$router.push('mobile_my')
    }
  },
  beforeDestroy() {},
}
</script>
<style lang="less" scoped>
.home {
  .home_box {
  width: 100%;
  .content{
    .banner_box{
      width: 100%;
      height: 700px;
      display: flex;
      align-items: center;
      padding:120px 20px 0 20px ;
      box-sizing: border-box;
      background: #FFF1F1;
      .box{
        // max-width:1440px;
        margin: 0 auto;
        .banner_left_box{
          .banner_title{
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            font-family: MicrosoftYaHei-Bold;
            letter-spacing: 0em;
          }
          .banner_btn{
            cursor: pointer;
            margin-top:30px ;
            width: 108px;
            padding: 18px 32px;
            border-radius: 4px;
            background: #FF163A;
            box-shadow: 0px 0.01px 0.02px 0px rgba(255, 22, 58, 0.25);
            font-family: MicrosoftYaHei-Bold;
            font-size: 20px;
            font-weight: 600;
            line-height: 0.25rem;
            text-align: center;
            letter-spacing: 0em;
            color: #fff;
          }
        }
      >img{
        margin-top: 40px;
        width: 100%;
      }
      }
    }
    .content_img_box{
      width: 100%;
      margin-bottom:80px;
      .img_box{
        width: 100%;
        >img{
          width:100%;
        }
      }
    }
  }
}
}
</style>