<!--
 * @Date: 2021-01-12 13:35:56
 * @LastEditTime: 2021-01-14 12:00:07
 * @Author: zhangyang
 * @LastEditors: zhangyang
 * @Description: 移动端 社区精选
 * @FilePath: \portal\src\views\mobile_community\index.vue
-->
<template>
  <div class="mobileCommunity">
    <comp-mobile-head :logoColor="true" :navIndex="1" :bgColor="true" />
    <section class="content flex between">
      <div class="card" v-for="(v, i) in list" :key="i" :style="`grid-row: auto / span ${ 20 + Math.ceil(v.title.length / 11 ) * 1};`">
        <div class="img">
          <img v-lazy="v.bg_img" :alt="v.bg_img_alt" />
        </div>
        <div class="info">
          <div class="title text-28">{{ v.title }}</div>
          <div class="user-box flex between align">
            <div class="user flex align text-24">
              <div class="img">
                <img v-lazy="v.user_img" alt="头像" />
              </div>
              <span>{{ v.user_name }}</span>
            </div>
            <div class="six-box flex align">
              <img v-lazy="require(`../../assets/img/common/six.png`)" alt="点赞"/>
              <span>{{ v.six_num }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <comp-mobile-footer :flex_bgColor="list.length < 3 ? true : false" :scrollTop="scrollTop" :maxScrollTop='880' />
  </div>
</template>

<script>
export default {
  name: 'MobileCommunity',
  data() {
    return {
      list: [
        {
          bg_img: require('../../assets/img/post/1.jpeg'),
          bg_img_alt: '照片',
          title: '沪上首家钓虾馆，肥美大虾现钓现烹，超新鲜',
          user_img: require('../../assets/img/user_head/1.jpg'),
          user_name: '没搞砸',
          six_num: '7.7w',
        },
        {
          bg_img: require('../../assets/img/post/10.jpg'),
          bg_img_alt: '照片',
          title: '早餐一碗面，开心每一天',
          user_img: require('../../assets/img/user_head/10.jpeg'),
          user_name: '吸溜溜',
          six_num: '3.7w',
        },
        {
          bg_img: require('../../assets/img/post/20210511_1.png'),
          bg_img_alt: '照片',
          title: '闹市区花园洋房四人餐吃一顿多少钱？',
          user_img: require('../../assets/img/user_head/20210511.jpg'),
          user_name: '丁子沪',
          six_num: '13w',
        },
        {
          bg_img: require('../../assets/img/post/20210511_2.png'),
          bg_img_alt: '照片',
          title: '陆家嘴穹顶花园吃一顿西餐多少钱？',
          user_img: require('../../assets/img/user_head/20210511.jpg'),
          user_name: '丁子沪',
          six_num: '1.1w',
        },
        {
          bg_img: require('../../assets/img/post/20210511_3.png'),
          bg_img_alt: '照片',
          title: '外滩吃一顿花胶鸡多少钱？',
          user_img: require('../../assets/img/user_head/20210511.jpg'),
          user_name: '丁子沪',
          six_num: '1w',
        },
        {
          bg_img: require('../../assets/img/post/5.jpeg'),
          bg_img_alt: '照片',
          title: '魔都夯食⏩一起大力食8⃣',
          user_img: require('../../assets/img/user_head/5.jpg'),
          user_name: '伜斓丶',
          six_num: '3621',
        },
        {
          bg_img: require('../../assets/img/post/6.jpeg'),
          bg_img_alt: '照片',
          title: '北京探店｜爱上烤鱼辣得过瘾属这家‼️辣尚瘾',
          user_img: require('../../assets/img/user_head/6.jpg'),
          user_name: '拉菲🎀',
          six_num: '3246',
        },
        {
          bg_img: require('../../assets/img/post/20210511_4.png'),
          bg_img_alt: '照片',
          title: '独栋别墅吃顿法式铁板烧需要多少钱？',
          user_img: require('../../assets/img/user_head/20210511.jpg'),
          user_name: '丁子沪',
          six_num: '1w',
        },
        {
          bg_img: require('../../assets/img/post/8.jpeg'),
          bg_img_alt: '照片',
          title: '上海夯食料理探店',
          user_img: require('../../assets/img/user_head/8.jpg'),
          user_name: '陈不陈',
          six_num: '2786',
        },
        {
          bg_img: require('../../assets/img/post/9.jpeg'),
          bg_img_alt: '照片',
          title: '北京性价比最高的京菜店！一口酥烤鸭绝了',
          user_img: require('../../assets/img/user_head/9.jpeg'),
          user_name: '妖妖乐游记',
          six_num: '8502',
        },
      ],
      scrollTop: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.windowScroll)
  },
  methods: {
    windowScroll() {
      this.scrollTop = document.documentElement.scrollTop
    },
  },
  beforeDestroy() {},
}
</script>
<style lang="less" scoped>
.mobileCommunity {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 1.04rem;
  .content {
    width: 100%;
    min-height: 77vh;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0.24rem 0.32rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(3.31rem, 1fr));
    grid-gap: 0.2rem;
    grid-auto-flow: row dense;
    grid-auto-rows: 0.085rem;
    .card {
      width: 3.31rem;
      min-height: 4.5rem;
      border-radius: 0.1rem;
      overflow: hidden;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      margin: 0 auto;
      // margin-bottom: 0.1rem;
      // margin-right: 0.1rem;
      .img {
        width: 100%;
        height: 4.44rem;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        box-sizing: border-box;
        padding: 0.1rem;
      }
      .title {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: left;
      }
      .user-box {
        margin-top: 0.1rem;
        .user {
          .img {
            width: 0.4rem;
            height: 0.4rem;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 0.05rem;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .six-box {
          > img {
            width: 0.28rem;
            height: 0.28rem;
            margin-right: 0.05rem;
          }
        }
      }
    }
  }
}
</style>