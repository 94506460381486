<template>
    <div class="cooperation">
        <comp-mobile-head :navIndex="3" :logoColor="scrollTop > 120 ? true : false" :bgColor="scrollTop > 120 ? true : false" />
        <section class="content">
            <div class="cooperation_box">
                <div class="box">
                    <h2 class="font-style text-48">欢迎合作</h2>
                    <h3 class="not-width text-28" style="margin-bottom:60px;">Welcome Cooperation</h3>
                    <van-form @submit="submit">
                        <van-field
                            v-model="formData.content"
                            rows="4"
                            autosize
                            label="留言"
                            type="textarea"
                            maxlength="300"
                            show-word-limit
                            placeholder="请在此处填写留言内容，我们会尽快与您联系。（必填）"
                            :rules="[{ required: true, message: '请填写留言内容' }]"
                        />
                        <van-field
                            v-model="formData.name"
                            name="用户名"
                            label="姓名"
                            placeholder="请填写您的姓名。（选填）"
                            maxlength="20"
                            show-word-limit
                        />
                        <van-field
                            v-model="formData.phone"
                            type="tel"
                            name="手机号"
                            label="手机号"
                            placeholder="请填写您的号码。（必填）"
                            maxlength="11"
                            show-word-limit
                            :rules="[{ required: true, message: '请填写您的号码' }]"
                        />
                        <van-field
                            v-model="formData.address"
                            type="text"
                            name="地址"
                            label="地址"
                            maxlength="100"
                            show-word-limit
                            placeholder="请填写您的地址。（选填）"
                        />
                        <van-field
                            v-model="formData.wechat"
                            type="text"
                            name="微信"
                            label="微信"
                            maxlength="30"
                            show-word-limit
                            placeholder="请填写您的微信号。（选填）"
                        />
                        <div style="margin: 16px;">
                            <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" round block type="info" native-type="submit" :loading="loading">提交</van-button>
                            <van-button color="#ff6034" plain hairline round block type="info" @click="$router.back()" style="margin-top:20px;">取消</van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { saveContact } from '@/api/common'
export default {
    name:'Cooperation',
    data() {
        return {
            scrollTop:0,
            formData:{
                content:'',
                name:'',
                phone:'',
                address:'',
                wechat:'',
            },
            loading: false
        }
    },
    mounted() {
        window.addEventListener('scroll', this.windowScroll)
    },
    methods:{
        windowScroll() {
            this.scrollTop = document.documentElement.scrollTop
        },
        submit(){
            this.loading = true
            saveContact(this.formData)
            .then(res => {
                console.log("res", res)
                this.loading = false
                this.$message.success('留言成功')
                this.dialogFlag = false
                this.$router.back()
            })
            .catch(() => {
                this.loading = false
            })
        } 
    }
}
</script>
<style lang="less" scoped>
    .cooperation{
        width: 100%;
        .cooperation_box{
            margin: 0rem 0;
            margin-top:100px;
            width: 100%;
            color: #000;
            .box{
                width: 6.8rem;
                margin: 0 auto;
            }
        }
        .font-style{
            letter-spacing: 0.05rem;
            font-family: PingFangSC-Semibold, PingFang SC;
        }
        .not-width{
            font-weight: 400;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: rgba(0, 0, 0, 0.65);
        }
    }
</style>
